import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from '../views/NotFound';
import Index from '../views/Index';
import {Logger} from 'aws-amplify';

Vue.use(VueRouter);

const logger = new Logger('router');

// ルーティング定義
const routes = [
  {
    path: '/notfound',
    name: 'notfound',
    component: NotFound,
  },
  {
    path: '/index',
    name: 'index',
    component: Index,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  // 画面遷移時に最上部にスクロール
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0};
    }
  },
});

// ルーティングの解決
router.beforeResolve(((to, from, next) => {
  logger.info(
      'beforeResolve to:', to.path, 'next: ', next.name, to.matched.length,
      !to.matched.length,
  );

  // ルートの場合の遷移
  if (to.path === '/') {
    logger.info('root to index');
    return next({name: 'index'});
  }

  // 存在しないパス
  if (!to.matched.length) {
    return next({name: 'notfound'});
  }
  next();
}));

export default router;
