// https://vuex.vuejs.org/ja/guide/actions.html

import mutationTypes from '@/store/mutationTypes';
import { API, graphqlOperation, Logger } from 'aws-amplify';
import {
  byStatusAndEndAt,
} from '../graphql/queries';
import { UsedStatus } from '../graphql/types';
import moment from 'moment';
import { updateKeycode } from '../graphql/mutations';
import QRCode from 'qrcode';

const logger = new Logger('actions');

// 取得済みに更新するために、有効なキーコードをまとめて取得する数
const BULK_LIST_KEYCODES_NUM = 50;

const ERRORS_NO_KEYCODE = '取得できるキーコードがありません。';
const ERRORS_FAILED_AND_TRY_AGAIN = 'キーコードの取得に失敗しました。再度お試しください。';

const Actions = {
  /**
   * キーコードの取得
   * @param context
   * @param payload
   */
  getKeycode: async (context, payload) => {
    logger.info('getSecuritiesNo', payload);

    // 有効なキーコードから1件取得する
    const result = await getAvailableKeycode(context, payload);
    if (result) {
      context.commit(mutationTypes.SET_KEYCODE_RESPONSE, {
        ...result,
      });
      const qrCodeSrc = await QRCode.toDataURL(result.keycode);
      context.commit(mutationTypes.SET_QRCODE, qrCodeSrc);
    } else {
      context.commit(mutationTypes.SET_QRCODE, '');
    }
  },

  /**
   * 取得したキーコード情報をクリア
   * @param context
   */
  clearKeycode: (context) => {
    logger.info('clearKeycode');
    context.commit(mutationTypes.CLEAR_KEYCODE_RESPONSE);
    context.commit(mutationTypes.SET_QRCODE, '');
  },
};

/**
 * 利用可能なキーコードを取得する
 * @param context
 * @param payload
 * @returns {Promise<boolean>}
 */
async function byStatusAndEndAtApi(context, payload) {
  logger.info('byStatusAndEndAtApi', payload);
  try {
    const today = moment().toISOString(true);

    const result = await API.graphql(graphqlOperation(byStatusAndEndAt, {
        status: UsedStatus.UNUSED,
        // 有効期間（終了日）が検査日以上
        endAt: { ge: payload.inspectionDate },
        filter: {
          and: [
            // 有効期間（開始日）が検査日以下
            { startedAt: { le: payload.inspectionDate } },
            // ダウンロード有効期限以内
            { downloadExpiredAt: { ge: today } },
          ],
        },
        // 取得を試みるレコード数
        limit: BULK_LIST_KEYCODES_NUM,
      },
    ));
    logger.info('byStatusAndEndAtApi', result);

    return (result.data.byStatusAndEndAt.items.length > 0)
      ? result.data.byStatusAndEndAt.items
      : false;
  } catch (e) {
    logger.error('byStatusAndEndAtApi', e);
  }

  return false;
}

/**
 * キーコードを取得する
 * @param context
 * @param payload
 * @returns {Promise<boolean>}
 */
async function getAvailableKeycode(context, payload) {
  logger.info('getAvailableKeycode', payload);

  // 検査日から、有効期限での利用可能なキーコード検索
  const results = await byStatusAndEndAtApi(
    context, payload,
  );

  // そもそも取得可能なキーコードがない場合
  if (!results) {
    context.commit(mutationTypes.SET_KEYCODE_RESPONSE, {
      errors: ERRORS_NO_KEYCODE,
    });
    return false;
  }

  // 有効なキーコードが存在する場合
  // キーコードを取得済みに更新する
  // 更新できるまで試みる
  // 最初に更新できたキーコードを返す
  for (const i in results) {
    // キーコードを取得済みに更新する
    const record = results[i];
    const request = {
      ...record,
      securitiesNo: payload.securitiesNo,
      inspectionDate: payload.inspectionDate,
    };
    const result = await updateKeycodeToUsed(request);
    if (result) {
      return record;
    }
  }

  // 一定数のレコードの更新失敗
  // リトライを促す
  context.commit(mutationTypes.SET_KEYCODE_RESPONSE, {
    errors: ERRORS_FAILED_AND_TRY_AGAIN,
  });

  logger.info('getAvailableKeycode.retry message');
  return false;
}

/**
 * 指定のキーコードを取得済みに更新する
 * @param request
 * @returns {Promise<boolean>}
 */
async function updateKeycodeToUsed(request) {
  logger.info('updateKeycodeToUsed', request);
  try {
    const result = await API.graphql(graphqlOperation(updateKeycode, {
      input: {
        keycode: request.keycode,
        securitiesNo: request.securitiesNo, // 証券番号
        status: UsedStatus.USED, // 利用ステータス
        inspectionDate: request.inspectionDate, // 検査日(JST)
        acquiredDateAt: moment().toISOString(true), // 取得日時(JST)

        // ソートキーのため設定が必要
        startedAt: request.startedAt,
        endAt: request.endAt,
      },
      condition: {
        status: { eq: UsedStatus.UNUSED },
      },
    }));

    logger.info('updateKeycodeToUsed.result', result);
    return true;

  } catch (e) {
    logger.error('updateKeycodeToUsed', e);
  }

  return false;
}

export default Actions;
