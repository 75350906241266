<template>
  <v-container>
    <div class="mt-3 mb-4 text-h6 text-md-h5 font-weight-bold">Door.アプリのご利用方法</div>
    <v-row no-gutters justify="start" class="usage__wrapper mb-2">
      <v-alert
          class="py-1 mt-4 rounded-0 font-weight-bold"
          border="left"
          colored-border
          color="accentColor"
      >
        １．アプリをダウンロード、新規登録する
      </v-alert>
    </v-row>

    <v-row no-gutters justify="center" class="mb-2 usage__wrapper">
      <v-col
          xs="6"
          sm="4"
          md="3"
          xl="3"
          align="center"
          align-self="center"
      >
        <vue-carousel :per-page="1" center-mode navigation-enabled loop
                      class="mb-6 vue-carousel">
          <vue-carousel-slide v-for="(item, index) in usageImages1" :key="index" style="width: 100%;">
            <v-img
                :src="item"
                style="width: 80%;"
            />
          </vue-carousel-slide>
        </vue-carousel>
      </v-col>
      <v-col xs="4" sm="8" md="9" xl="9"
             class="px-xl-12 px-lg-12 px-md-12 px-sm-6"
             align="start">
        <v-row no-gutters>
          <p>1.Apple storeまたはGoogle Playから「Door.」で検索、無料でダウンロードできます。</p>

          <p>2.「一般の方」を選択し、「会員登録する」から必要な情報を登録してください。</p>
        </v-row>
        <!-- ストアリンク -->
        <v-row no-gutters justify="center">
          <v-col align="end" align-self="center">
            <a href="https://apps.apple.com/jp/app/door/id1521407649" target="_blank">
              <v-img class="ml-auto mr-4" max-width="135px" src=../assets/images/appstore.svg></v-img>
            </a>
          </v-col>
          <v-col align-self="center">
            <a href="https://play.google.com/store/apps/details?id=com.medrt.Door" target="_blank">
              <v-img class="mr-auto ml-4" max-width="155px" src=../assets/images/google-play-badge.svg></v-img>
            </a>
          </v-col>
        </v-row>
        <!-- QRコード -->
        <v-row no-gutters>
          <v-col align="end" align-self="center" class="d-none d-sm-block">
            <v-img class="ml-auto mr-0" max-width="155px" src=../assets/images/qr-app_2x.png></v-img>
          </v-col>
          <v-col align-self="center" class="d-none d-sm-block">
            <v-img class="mr-auto ml-3" max-width="155px" src=../assets/images/qr-google_2x.png></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-alert
        class="py-1 mt-4 rounded-0 font-weight-bold"
        border="left"
        colored-border
        color="accentColor"
    >
      ２．相談キーを登録する
    </v-alert>

    <v-row no-gutters justify="center" class="usage__wrapper mb-2">
      <v-col
          xs="6"
          sm="4"
          md="3"
          xl="3"
          align="center"
          align-self="center"
      >
        <vue-carousel :per-page="1" center-mode navigation-enabled loop
                      class="mb-6 vue-carousel">
          <vue-carousel-slide v-for="(item, index) in usageImages2" :key="index" style="width: 100%;">
            <v-img
                :src="item"
                style="width: 80%;"
            />
          </vue-carousel-slide>
        </vue-carousel>
      </v-col>
      <v-col xs="6" sm="8" md="9" xl="9"
             class="px-xl-12 px-lg-12 px-md-12 px-sm-6"
             align="start">
        <p>1.「相談・回答」ボタンを押下してください。</p>
        <p class="d-none d-sm-none d-md-block">2.こちらのページ上部に表示されたQRコードをスキャン、またはキーコード<span class="font-weight-bold"
            v-if="keycode !== '・・・・・・'">「{{ keycode }}」</span>を入力して相談キーを登録してください。</p>
        <p v-if="keycode !== '・・・・・・'" class="d-block d-sm-block d-md-none">キーコード<span class="font-weight-bold"
        >「{{ keycode }}」</span>を入力し、相談キーを登録してください。</p>
        <p style="font-size: 0.85rem">&nbsp;&nbsp;&nbsp;※相談キー画面の右上「＋追加」をクリックして相談キーの追加もできます。</p>
      </v-col>
    </v-row>
    <v-alert
        class="py-1 mt-4 rounded-0 font-weight-bold"
        border="left"
        colored-border
        color="accentColor"
    >
      ３．相談を投稿する
    </v-alert>
    <v-row no-gutters justify="center" class="usage__wrapper mb-2">
      <v-col
          xs="6"
          sm="4"
          md="3"
          xl="3"
          align="center"
          align-self="center"
      >
        <vue-carousel :per-page="1" center-mode navigation-enabled loop
                      class="mb-6 vue-carousel">
          <vue-carousel-slide v-for="(item, index) in usageImages3" :key="index" style="width: 100%;">
            <v-img
                :src="item"
                style="width: 80%;"
            />
          </vue-carousel-slide>
        </vue-carousel>
      </v-col>
      <v-col xs="6" sm="8" md="9" xl="9"
             class="px-xl-12 px-lg-12 px-md-12 px-sm-6"
             align="start">
        <p>1.登録した相談キーを選択し、「相談」ボタンを押下してください。</p>

        <p>2.相談したい内容を入力してください。</p>
        <p style="font-size: 0.85rem">&nbsp;&nbsp;&nbsp;※相談内容入力画面では、「自分」を選択するようお願いします。</p>

        <p>3.入力した相談内容を確認後、「この内容で相談する」ボタンを押下すると、相談が投稿されます。</p>
      </v-col>
    </v-row>


    <v-alert
        class="py-1 mt-4 rounded-0 font-weight-bold"
        border="left"
        colored-border
        color="accentColor"
    >
      ４．チャットで相談
    </v-alert>
    <v-row no-gutters justify="center" class="usage__wrapper mb-2">
      <v-col
          xs="6"
          sm="4"
          md="3"
          xl="3"
          align="center"
          align-self="center"
      >
        <vue-carousel :per-page="1" center-mode navigation-enabled loop
                      class="mb-6 vue-carousel">
          <vue-carousel-slide v-for="(item, index) in usageImages4" :key="index" style="width: 100%;">
            <v-img
                :src="item"
                style="width: 80%;"
            />
          </vue-carousel-slide>
        </vue-carousel>
      </v-col>
      <v-col xs="6" sm="8" md="9" xl="9"
             class="px-xl-12 px-lg-12 px-md-12 px-sm-6"
             align="start">
        <p>1.「相談一覧」で相談状況がわかります。</p>

        <p>2.医師からの連絡をもって、Roomに入り、チャット相談が開始されます。</p>

        <p style="font-size: 0.85rem">&nbsp;&nbsp;&nbsp;※画像の共有も可能です。</p>

        <p style="font-size: 0.85rem">&nbsp;&nbsp;&nbsp;※タイトルを押下すると投稿内容を確認できます。</p>
      </v-col>
    </v-row>

    <v-alert
        class="py-1 mt-4 rounded-0 font-weight-bold"
        border="left"
        colored-border
        color="accentColor"
    >
      ５．ビデオ通話で相談
    </v-alert>
    <v-row no-gutters justify="center" class="usage__wrapper mb-2">
      <v-col
          xs="6"
          sm="4"
          md="3"
          xl="3"
          align="center"
          align-self="center"
      >
        <vue-carousel :per-page="1" center-mode navigation-enabled loop
                      class="mb-6 vue-carousel">
          <vue-carousel-slide v-for="(item, index) in usageImages5" :key="index" style="width: 100%;">
            <v-img
                :src="item"
                style="width: 80%;"
            />
          </vue-carousel-slide>
        </vue-carousel>
      </v-col>
      <v-col xs="6" sm="8" md="9" xl="9"
             class="px-xl-12 px-lg-12 px-md-12 px-sm-6"
             align="start">
        <p>1.必要に応じてビデオ通話での相談が可能です。</p>

        <p>2.予約した時間帯に医師から着信があります。</p>

        <p>3.医師が今通話可能な場合には、「通話OK」で今すぐに相談できます。</p>

        <p style="font-size: 0.85rem">&nbsp;&nbsp;&nbsp;※ビデオ通話終了後に、医師から追加で情報が提供されることもあります。</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// Doorのご利用方法
import {Carousel as VueCarousel, Slide as VueCarouselSlide} from 'vue-carousel';

export default {
  name: 'DoorUsage',
  components: {
    'VueCarousel': VueCarousel,
    'VueCarouselSlide': VueCarouselSlide,
  },
  props: {
    keycode: {
      type: String,
    },
  },
  data() {
    return {
      // ご利用手順の画像
      usageImages1: [
        {src: '/assets/images/screen01-1-1.png'},
        {src: '/assets/images/screen01-1-2.png'},
        {src: '/assets/images/screen01-1-3.png'},
        {src: '/assets/images/screen01-1-4.png'},
        {src: '/assets/images/screen01-1-5.png'},
      ],
      usageImages2: [
        {src: '/assets/images/screen02-1-1.png'},
        {src: '/assets/images/screen02-1-2.png'},
        {src: '/assets/images/screen02-1-3.png'},
        {src: '/assets/images/screen02-1-4.png'},
      ],
      usageImages3: [
        {src: '/assets/images/screen02-2-1.png'},
        {src: '/assets/images/screen02-2-2.png'},
        {src: '/assets/images/screen02-2-3.png'},
        {src: '/assets/images/screen02-2-4.png'},
        {src: '/assets/images/screen02-2-5.png'},
      ],
      usageImages4: [
        {src: '/assets/images/screen02-3-1.png'},
        {src: '/assets/images/screen02-3-2.png'},
        {src: '/assets/images/screen02-3-3.png'},
      ],
      usageImages5: [
        {src: '/assets/images/screen02-4-1.png'},
        {src: '/assets/images/screen02-4-2.png'},
        {src: '/assets/images/screen02-4-3.png'},
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.usage__wrapper {
  ::v-deep .v-image {
    height: auto !important;
  }
}

@media screen and (max-width: 959px) {
  .usage__wrapper p {
    font-size: 14px;
  }
}

.vue-carousel {
  width: 80%;
  height: auto;
}
</style>