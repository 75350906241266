<template>
  <div>
    <v-row class="py-3">
      <v-col>
        <h2>{{ pageTitle }}</h2>
      </v-col>
    </v-row>
    <v-card class="mx-auto"></v-card>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data: () => {
    return {
      pageTitle: 'ページが見つかりません。'
    }
  },
}
</script>
