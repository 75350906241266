<template>
  <v-main>
    <header-component/>

    <v-container class="container__wrapper mt-4 pt-0">
      <!-- 概要 -->
      <v-row no-gutters justify="center" class="my-0">
        <v-img
            :src=bannerPath
        ></v-img>
      </v-row>
      <v-row no-gutters dense class="mb-4 px-6" justify="start">
        <span class="text-caption">※1 MSAケアWebサービスを導入している法人・組織の構成員さまもご利用いただけます。</span>
      </v-row>
      <v-row no-gutters justify="center" class="my-4 my-md-8">
        <div class="text-body-2 text-md-h6 title__border pa-6">N-NOSE®検査を受けられた方が対象となります</div>
      </v-row>

      <v-row no-gutters justify="start" class="mb-4">
        <span class="text-h6 text-md-h5 font-weight-bold">ご利用の流れ</span>
      </v-row>
      <v-row no-gutters justify="start" class="mb-4">
        <ul class="px-4 px-md-8">
          <li><span class="my-0 text-body-2 text-md-body-1">現在ご加入の証券番号およびN-NOSE®検査日を入力すると、キーコードを取得できます。</span>
          </li>
          <li><span class="my-0 text-body-2 text-md-body-1">
            「Door.アプリのご利用方法」を一読いただき、アプリでキーコードを入力すると、サービスがご利用いただけます。</span></li>
        </ul>
      </v-row>
      <v-row no-gutters justify="start" class="mb-4">
        <span class="text-h6 text-md-h5  font-weight-bold">キーコードの取得</span>
      </v-row>

      <!-- 入力フォーム -->
      <v-form ref="form" v-model="valid">
        <!-- 証券番号 -->
        <v-row no-gutters justify="space-between" class="px-4 px-md-8 mb-2">
          <v-col cols="10" align="start">
            <span class="text-body-2 text-md-body-1 font-weight-bold">証券番号<span class="font-weight-bold" style="vertical-align: super; font-size: x-small;">※2</span>をご入力ください。</span>
          </v-col>
          <v-col align="end" cols="2"><span class="form__required">必須</span></v-col>
        </v-row>
        <v-row no-gutters class="px-4 px-md-8">
          <span class="form__hint">ハイフン以下の枝番号がある場合は、ハイフン以下を除いてご入力ください。</span>
        </v-row>
        <v-row no-gutters class="px-4 px-md-8">
          <span class="form__hint">例）ｲ0001234567-1 => ｲ0001234567</span>
        </v-row>
        <v-row no-gutters class="px-4 px-md-8 mb-2">
          <span class="form__hint">※2 MSAケアWebサービスを導入している法人・組織の構成員さまは法人・組織コードをご入力ください。</span>
        </v-row>
        <v-row no-gutters justify="start" class="px-4 px-md-8">
          <v-text-field
              ref="securitiesNo"
              v-model="securitiesNo"
              :rules="securitiesNoRules"
              type="text"
              inputmode="text"
              required
              placeholder=""
              outlined
              maxlength="12"
              @input="inputSecuritiesNo"
          >
          </v-text-field>
        </v-row>

        <!-- 検査日 -->
        <v-row no-gutters justify="space-between" class="px-4 px-md-8 mb-2 mt-4">
          <v-col cols="10" align="start">
            <span class="text-body-2 text-md-body-1 font-weight-bold">N-NOSE®検査日をご入力ください。</span>
          </v-col>
          <v-col align="end" cols="2"><span class="form__required">必須</span></v-col>
        </v-row>
        <v-row no-gutters class="px-4 px-md-8 mb-2">
          <span class="form__hint">1年前までの日付がご入力できます。</span>
        </v-row>
        <v-row no-gutters justify="start" class="px-4 px-md-8">
          <v-menu
              ref="dateMenu"
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateAt"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="dateAt"
                no-title
                scrollable
                locale="jp-ja"
                :day-format="date => new Date(date).getDate()"
                :min="minInspectionDate"
                :max="new Date().toISOString().substr(0, 10)"
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-row>

        <!-- キーコード -->
        <v-row no-gutters justify="center" class="my-2 mx-8">
          <span style="color: #455A64" class="text-caption">
          三井住友海上あいおい生命は、MRT株式会社が運営する「Door. into 健康医療相談」を通してN-NOSE®検査を受検されたお客さまの医師への健康相談サービスをご提供いたします。
          本サービスのご利用状況の確認を目的として、三井住友海上あいおい生命はMRT株式会社より電子データによって以下の情報を取得いたします。<br>
          ①   本サイトにてご記入いただく証券番号、法人・組織コード、およびN-NOSE®検査日<br>
          ②   以下にて取得したキーコードをアプリDoor.にてご利用になった際のキーコード、相談投稿日、チャットまたはビデオ相談のご利用日時<br>
          ※具体的な相談内容、通話内容については取得いたしません。
          </span>
        </v-row>
        <v-row no-gutters justify="center" class="my-4">
          <v-btn
              :disabled="!enableGetKeycode"
              :loading="loading"
              class="mt-4"
              color="mainColor"
              :dark="enableGetKeycode"
              x-large
              @click="clickedGetKeycode"
          >
            <span style="font-weight: bold">同意してキーコードを取得する</span>
          </v-btn>
        </v-row>
        <v-row no-gutters justify="center" class="mt-8" v-if="errors !== ''">
          <span class="red--text text-caption">{{ errors }}</span>
        </v-row>
        <v-row no-gutters justify="center" class="mt-10 mb-8">
          <div class="keycode__border">
            <p class="keycode__title text-center font-weight-bold text-h6 mx-auto">キーコード</p>
            <p class="text-center font-weight-bold mt-0 mb-2"> {{ this.displayKeycode }}</p>
            <div class="text-center" v-if="qrCodeImageSrc!==''">
              <img class="d-none d-sm-inline-block" v-bind:src="qrCodeImageSrc" alt=""
                   style="width: 160px; height: 160px;"/>
            </div>
            <v-btn
                ref="copyButton"
                v-if="!isEmptyKeycode"
                v-on:click="copyText"
                outlined
                class="mb-6 mt-4 pt-0 d-md-none d-sm-none"
                color="accentColor"
            >
              {{ copied ? 'コピーしました' : 'キーコードをコピー' }}
            </v-btn>
          </div>
        </v-row>
      </v-form>
    </v-container>

    <!-- Door.ご利用方法 -->
    <door-usage :keycode="keycode" class="container__wrapper"/>

    <!-- 注意事項 -->
    <v-container class="container__wrapper mb-4">
      <v-card
          class="mx-auto px-4 px-md-8 py-4 mb-4"
          outlined
      >
        <ul>
          <li class="mb-2"><span class="text-body-2">本サービスは、三井住友海上あいおい生命株式会社よりMRT株式会社に運営を委託しています。</span></li>
          <li class="mb-2"><span class="text-body-2">N-NOSE®は、（株）HIROTSUバイオサイエンスの登録商標です。</span></li>
        </ul>
      </v-card>

      <v-row no-gutters justify="center" class="mt-6">
        <span class="text-body-2">アプリDoor.に関するお問合せ</span>
      </v-row>
      <v-row no-gutters justify="center">
        <span class="text-body-2">Door. 事務局（平日9：00～18：00）</span>
      </v-row>
      <v-row no-gutters justify="center">
        <span class="text-body-2">URL : <a href="https://medoor.com/webmed/">https://medoor.com/webmed/</a></span>
      </v-row>
      <v-row no-gutters justify="center">
        <span class="text-body-2">TEL : <a href="tel:050-5445-9145">050-5445-9145</a></span>
      </v-row>
      <v-row no-gutters justify="center">
        <span class="text-body-2">Mail : <a href="mailto:n-nose_support@medrt.com">n-nose_support@medrt.com</a></span>
      </v-row>
      <v-row no-gutters justify="center">
        <span class="text-body-2" style="text-align: center">運営会社 : MRT株式会社<br v-if="isMobile()===true">（東証マザーズ［証券コード：6034］）</span>
      </v-row>
    </v-container>

    <footer-component/>
  </v-main>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import DoorUsage from '@/components/DoorUsage';
import {Logger} from 'aws-amplify';
import {mapActions, mapGetters} from 'vuex';
import moment from 'moment';

const logger = new Logger('index');

const ERROR_MESSAGE_REQUIRED_VALIDATION = '6〜12桁でご入力ください';
const ERROR_MESSAGE_DIGIT_VALIDATION = '6〜12桁でご入力ください';
const ERROR_MESSAGE_CHARACTER_VALIDATION = '入力に使用できるのは、半角英数字、半角カナとなります';
const DEFAULT_KEYCODE = '・・・・・・';
// 証券番号の最大桁数
const MAX_SECURITIES_NO_NUM = 12;
// 証券番号の最小桁数
const MIN_SECURITIES_NO_NUM = 6;

export default {
  name: 'Index',
  components: {
    'header-component': Header,
    'footer-component': Footer,
    'door-usage': DoorUsage,
  },

  created() {
    logger.info('created');
    // 初期値を設定
    this.inspectionDate = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(
        0,
        10,
    );
  },

  data() {
    return {
      // 検査日
      inspectionDate: '',
      // フォーム入力チェック（trueで相談キー取得ボタンが押せる様になる）
      valid: false,
      // 証券番号バリデーション
      validSecuritiesNo: false,
      // 通信中（相談キー取得ボタンの表示切替用）
      loading: false,
      // キーコードのコピー状態
      copied: false,
      // 証券番号
      securitiesNo: '',
      securitiesNoRules: [
        v => !!v || ERROR_MESSAGE_REQUIRED_VALIDATION, // 未入力チェック
        v => /^[a-zA-Z0-9\uFF61-\uFF9F]+$/.test(v) || ERROR_MESSAGE_CHARACTER_VALIDATION,  // 半角英数字+半角カナチェック
        v => (!!v && (v.length >= MIN_SECURITIES_NO_NUM && v.length <= MAX_SECURITIES_NO_NUM)) || ERROR_MESSAGE_DIGIT_VALIDATION, // 桁数チェック
      ],

      // 日付選択メニューの表示有無
      dateMenu: false,
    };
  },

  methods: {

    // キーコード取得ボタンが押された
    async clickedGetKeycode() {
      logger.info('clickedGetKeycode');

      try {
        // ローディング中に設定
        this.loading = true;

        // 表示しているキーコードを非表示にする
        this.clearKeycode();

        // キーコード取得
        await this.getKeycode({
          // 証券番号にはバリデーション済みを指定する
          securitiesNo: this.securitiesNo,
          inspectionDate: this.inspectionDate,
        });
      } catch (e) {
        logger.error(e);
      } finally {
        this.loading = false;
      }
    },

    // 相談キーをクリップボードにコピーする
    async copyText() {
      try {
        const copyText = this.keycode;
        navigator.clipboard.writeText(copyText);
        this.copied = true;

        // 一定時間で戻す
        setTimeout(() => {
          this.copied = false;
        }, 2000);
      } catch (e) {
        logger.error(e);
      }
    },

    /**
     * モバイル判定
     */
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true;
        default:
          return false;
      }
    },

    /**
     * 証券番号が入力された
     */
    async inputSecuritiesNo(value) {
      logger.info('inputSecuritiesNo', value, value.length);
      this.validSecuritiesNo = false;
      this.clearKeycode();

      // 桁数チェック
      this.validSecuritiesNo = (value.length >= MIN_SECURITIES_NO_NUM && value.length <= MAX_SECURITIES_NO_NUM);
    },

    ...mapActions(['getKeycode', 'clearKeycode', 'clearMessages']),
  },
  computed: {
    /**
     * キーコード未入力か
     */
    isEmptyKeycode() {
      return this.displayKeycode === DEFAULT_KEYCODE;
    },

    /**
     * バナーのパス
     */
    bannerPath() {
      return (this.isMobile())
          ? require('@/assets/images/banner.jpg')
          : require('@/assets/images/banner_2x.jpg');
    },

    /**
     * 検査年月日
     */
    dateAt: {
      get() {
        return this.inspectionDate;
      },
      set(v) {
        logger.info('set dateAt', v);
        this.inspectionDate = v;
        this.dateMenu = false;
      },
    },

    /**
     * 検査日の最小日
     */
    minInspectionDate() {
      return moment().subtract(1, 'years').toISOString().substr(0, 10);
    },

    /**
     * 表示するキーコード
     * @returns {string|string}
     */
    displayKeycode() {
      return this.keycode || DEFAULT_KEYCODE;
    },

    /**
     * キーコード取得可能か
     */
    enableGetKeycode() {
      return this.valid && this.validSecuritiesNo;
    },

    ...mapGetters([
      'keycode',
      'errors',
      'qrCodeImageSrc',
    ]),
  },
};
</script>

<style lang="scss" scoped>

// タイトル下のカラー区切り線
.title__box {
  height: 45px;
  font-size: 16px;
  background-color: #006762;
}

// N-NOSE検査のボーダー
.title__border {
  margin: 0 auto;
  border: 1px solid #0EA292;
  border-radius: 26px;
  display: block;
  //  min-width: 400px !important;
  text-align: center;
}

// コンテナ設定
.container__wrapper {
  max-width: 1000px
}

// アラート（色付きの四角）
.v-alert ::v-deep .v-alert__content {
  font-size: 20px !important;
}

// キーコードを囲う線
.keycode__border {
  margin: 0 auto;
  border: 1px solid #0EA292;
  border-radius: 26px;
  display: block;
  min-width: 400px;
  text-align: center;
}

// キーコードタイトル
.keycode__title {
  background: #fff;
  margin-top: -15px;
  width: 30%;
}

// form 必須表示
.form__required {
  background-color: #C9171E;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  padding: 4px 8px;
  margin-right: 8px;
}

.form__hint {
  font-size: 12px;
}

@media screen and (max-width: 959px) {
  // キーコードを囲う線
  .keycode__border {
    min-width: 350px;
  }
}

// 証券番号バリデーションのエラーメッセージが、特定のブラウザで上部が途切れる問題の対応
.v-input ::v-deep .v-text-field__details {
  overflow: visible;
}

</style>
