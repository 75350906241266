// https://vuex.vuejs.org/ja/guide/mutations.html

import types from './mutationTypes';
import { Logger } from 'aws-amplify';

const logger = new Logger('mutations');
const Mutations = {
  /**
   * キーコード取得時のレスポンス設定
   * @param state
   * @param payload
   */
  [types.SET_KEYCODE_RESPONSE]: (state, payload) => {
    logger.info(types.SET_KEYCODE_RESPONSE, payload);
    state.keycodeResponse.keycode = payload.keycode || '';
    state.keycodeResponse.securitiesNo = payload.securitiesNo || '';
    state.keycodeResponse.inspectionDate = payload.inspectionDate || '';
    state.keycodeResponse.errors = payload.errors || '';
    state.keycodeResponse.messages = payload.messages || '';
  },

  /**
   * QRコードimage srcの設定
   * @param state
   * @param payload
   */
  [types.SET_QRCODE]: (state, payload) => {
    logger.info(types.SET_QRCODE, payload);
    state.qrCodeImageSrc = payload;
  },

  /**
   * キーコード取得時のレスポンスクリア
   * @param state
   */
  [types.CLEAR_KEYCODE_RESPONSE]: (state) => {
    state.keycodeResponse.keycode = '';
    state.keycodeResponse.securitiesNo = '';
    state.keycodeResponse.inspectionDate = '';
    state.keycodeResponse.errors = '';
    state.keycodeResponse.messages = '';
  },

  /**
   * メッセージ設定
   * @param state
   * @param payload
   */
  [types.SET_MESSAGES]: (state, payload) => {
    state.keycodeResponse.errors = payload.errors || '';
    state.keycodeResponse.messages = payload.messages || '';
  },
};
export default Mutations;
