<template>
  <footer>
    <div class="footer__wrapper">
      <div class="footer__area">
        <div class="copyright">© Copyright MRT Inc. All Rights Reserved.</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
<style lang="scss" scoped>
.footer__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  background-color: #006762;
}

.copyright {
  color: #ffffff;
}

@media screen and (max-width: 959px) {
  .copyright {
    font-size: 12px;
  }
}

</style>
