/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://g6ifcaphovfidktuxks2i35tpi.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "validateSecuritiesNo",
            "endpoint": "https://h7dhfrms84.execute-api.ap-northeast-1.amazonaws.com/main",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-1:5d5787b1-aae2-4712-b485-f2676628bf57",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_IvQAJbJGD",
    "aws_user_pools_web_client_id": "3kp3irc50ok3197ca8v46g1du9",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
