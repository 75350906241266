// https://vuex.vuejs.org/ja/guide/getters.html

const Getters = {
  /**
   * キーコード
   * @param state
   * @returns {string}
   */
  keycode: state => {
    return state.keycodeResponse.keycode;
  },

  /**
   * キーコード取得時のエラー
   * @param state
   * @returns {string}
   */
  errors: state => {
    return state.keycodeResponse.errors;
  },

  /**
   * QRコードのsrc
   * @param state
   * @returns {string}
   */
  qrCodeImageSrc: state => {
    return state.qrCodeImageSrc;
  },
};
export default Getters;
