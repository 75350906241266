import Vue from "vue";
import Vuex from 'vuex'
import States from './states'
import Mutations from './mutations'
import Actions from './actions'
import Getters from './getters'

Vue.use(Vuex);

const store = new Vuex.Store({
  state: States,
  mutations: Mutations,
  actions: Actions,
  getters: Getters,
  modules: {}
});

export default store;

