/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getKeycode = /* GraphQL */ `
  query GetKeycode($keycode: String!) {
    getKeycode(keycode: $keycode) {
      keycode
      startedAt
      endAt
      downloadExpiredAt
      status
      securitiesNo
      inspectionDate
      acquiredDateAt
      createdAt
      updatedAt
    }
  }
`;
export const listKeycodes = /* GraphQL */ `
  query ListKeycodes(
    $keycode: String
    $filter: ModelKeycodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKeycodes(
      keycode: $keycode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        keycode
        startedAt
        endAt
        downloadExpiredAt
        status
        securitiesNo
        inspectionDate
        acquiredDateAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byStatusAndEndAt = /* GraphQL */ `
  query ByStatusAndEndAt(
    $status: UsedStatus!
    $endAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKeycodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byStatusAndEndAt(
      status: $status
      endAt: $endAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        keycode
        startedAt
        endAt
        downloadExpiredAt
        status
        securitiesNo
        inspectionDate
        acquiredDateAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byStatusAndAcquiredDateAt = /* GraphQL */ `
  query ByStatusAndAcquiredDateAt(
    $status: UsedStatus!
    $acquiredDateAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKeycodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byStatusAndAcquiredDateAt(
      status: $status
      acquiredDateAt: $acquiredDateAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        keycode
        startedAt
        endAt
        downloadExpiredAt
        status
        securitiesNo
        inspectionDate
        acquiredDateAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
